import React from "react";

const Funfact = () => {
  return (
    <section className="funfact-wrapper section-bg section-padding text-white">
        <div className="container">
            <div className="funfact-content-grid bg-cover" style={{backgroundImage: "url('assets/img/funfact-line.png')"}}>
                <div className="single-funfact-item">
                    <div className="icon">
                        <i className="fal fa-gem"></i>
                    </div>
                    <h3>68</h3>
                    <p>Project Completed</p>
                </div>
                <div className="single-funfact-item">
                    <div className="icon">
                        <i className="fas fa-users"></i>
                    </div>
                    <h3>183</h3>
                    <p>Students</p>
                </div>
                <div className="single-funfact-item">
                    <div className="icon">
                        <i className="fas fa-address-book"></i>
                    </div>
                    <h3>127</h3>
                    <p>Clients</p>
                </div>
                <div className="single-funfact-item">
                    <div className="icon">
                        <i className="fal fa-trophy-alt"></i>
                    </div>
                    <h3>5</h3>
                    <p>Awards Winning</p>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Funfact;