import React from "react";

const Hero = () => {
    return (
        <section className="hero-slide-wrapper hero-3">
        <div className="hero-slider-3">
            <div className="single-slide bg-cover" style={{backgroundImage: "url('assets/img/home3/slide1.webp')"}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-8 mt-5 mt-lg-0 order-2 order-lg-1 text-center text-lg-start">
                            <div className="hero-contents text-white">
                                <h2>We Build Cutting Edge Tech Solutions</h2>
                                <p style={{fontSize: "16px"}}>With technology, solve complex business problems with innovation, creativity, and strategy.</p>
                                <a href="/services" className="theme-btn">Service we provide <i className="fas fa-arrow-right"></i></a>
                                <a href="/about" className="theme-btn minimal-btn">learn more <i className="fas fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-12 order-1 order-lg-2 text-center text-lg-right">
                    <div class="video-play-btn">
                        <a href="https://www.youtube.com/watch?v=JEduhSu3nvg" class="play-video popup-video" data-effect="mfp-zoom-in">
                        <i class="fas fa-play"></i>
                        </a>
                    </div> 
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}
export default Hero;