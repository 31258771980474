import React from "react";
import InfoTipCard from "./infoTipCard";

const InfoTips = () => {
  return (
    <div className="row mpt-50 pt-100">

        <InfoTipCard caption={"IT Consultancy"} tip={"Expert IT consultancy for businesses."} />

        <InfoTipCard caption={"Businesses Masterclass"} tip={"Business growth masterclass for entrepreneurs."} />

        <InfoTipCard caption={"Software Development"} tip={"Software development for innovative solutions."} />

    </div>
  );
};

export default InfoTips;