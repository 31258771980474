import React from "react";
// import Preloader from "./loader/preloader";
import HeaderWrap from "./loader/header-wrap";

const Header = () => {
    return (
    <>
        {/* <Preloader /> */}
        <HeaderWrap />
    </>
    )
}
export default Header;