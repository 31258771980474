import React from "react";

const AboutWrapper = () => {
  return (
    <section className="about-wrapper techex-landing-page">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="about-img" data-aos="fade-right">
                        <img src="assets/img/techex-landing-page/ath-vision.webp" className="img-fluid" alt="Artificail intelligence Nigeria"/>

                        <div className="video-play-btn" data-aos="fade-up" data-aos-delay="100">
                            <a href="https://www.youtube.com/watch?v=fe6CAl8x2zc" className="play-video popup-video"><i className="fas fa-play"></i></a>                    
                        </div>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="section-title">
                        <a href="#/" className="theme-btn-sm mb-15" data-aos="fade-left">ABOUT COMPANY</a>
                        <h1 data-aos="fade-left" data-aos-delay="100">Who we are</h1>
                        <div data-aos="fade-left" data-aos-delay="150">
                            <p>We are a team of creative tech innovators, equipped with a highly developed skill set and experiences not only in information technology, but also in business processes across a range of industries and sectors.
                            Our mission is to illuminate the tech space by cultivating excellent and professional relationship with our client to provide effective and reliable information technology solution for their needs.
                            </p>
                        </div>

                        <div className="rate-content-grid d-flex justify-content-between">
                            <div className="single-rate-item" data-aos="fade-up">
                                <h3>320</h3>
                                <p>Active Status Clients</p>
                            </div>
                            <div className="single-rate-item" data-aos="fade-up" data-aos-delay="100">
                                <h3>850</h3>
                                <p>Successful Projects</p>
                            </div>
                            <div className="single-rate-item" data-aos="fade-up" data-aos-delay="150">
                                <h3>35</h3>
                                <p>In-House Engineers</p>
                            </div> 
                        </div>

                        <a href="/about" className="theme-btn black" data-aos="fade-up" data-aos-delay="200">Know us more <i className="icon-arrow-right-1"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default AboutWrapper;