import React from "react";
import GeneralHero from "../components/general/generalHero";
import EasySolutions from "../components/services/easySolutions";
import ItSolutions from "../components/services/itSolution";
import Funfact from "../components/general/funfact";
import PopSolutions from "../components/services/popSolution";
import BrandCarousel from "../components/general/brandCarousel";
import CallToAction from "../components/general/callToAction";

const Services = () => {
  return (
  <>

    <GeneralHero bgText={"services"} theme={"Services"} subTheme={"services"} />

    <EasySolutions />

    <ItSolutions />

    <Funfact />

    <PopSolutions />

    <BrandCarousel />

    <CallToAction />

  </>
  );
}

export default Services;