import React from "react";

const EasySolutions = () => {
  return (
    <section className="servces-wrapper section-padding">
        <div className="container">
        <div className="row text-center mb-30">
                <div className="col-lg-8 p-lg-0 col-12 offset-lg-2">
                    <div className="section-title">
                    <h1 style={{ color:'white', fontWeight: 'bold'}} className="mb-3">Visit Our Academy Today</h1>
                      <h3 className="mb-3 text-white">At Abia Tech Hub Academy, we provide you with the skillsets for careers that power the world.</h3>
                      <h3 className="mb-3 text-white">Are you ready to embark on a journey that will shape your future? Look no further than Abia Tech Hub Academy. We are a leading institution dedicated to equipping individuals like you with the knowledge and skills necessary to thrive in the rapidly evolving tech industry.</h3>
                      <a href="https://academy.abiatechhub.com/" class="theme-btn mt-4 mt-xl-0 mb-5">Get Started <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            {/* <div className="row text-center mb-30">
                <div className="col-lg-8 p-lg-0 col-12 offset-lg-2">
                    <div className="section-title">
                        <p>How can we help you</p>
                        <h1>We Provide Best IT Solutions For Business</h1>
                    </div>
                </div>
            </div> */}

            {/* <div className="row justify-content-between">
                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-service">
                        <div className="icon">
                            <i className="flaticon-monitor"></i>
                        </div>
                        <div className="content">
                            <h3>Quality Service</h3>
                            <p>Our commitment to delivering top-notch service ensures customer satisfaction and exceeds expectations through exceptional quality and attention to detail.s</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-service">
                        <div className="icon">
                            <i className="flaticon-user"></i>
                        </div>
                        <div className="content">
                            <h3>Exclusive Members</h3>
                            <p>Exclusive Members enjoy special privileges, personalized experiences, and exclusive access to premium features, creating a truly exceptional and tailored membership experience.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-service">
                        <div className="icon">
                            <i className="flaticon-tool"></i>
                        </div>
                        <div className="content">
                            <h3>Quality Support</h3>
                            <p>We ensure prompt assistance, expert guidance, and reliable solutions, delivering exceptional customer satisfaction and ensuring smooth operations for businesses and individuals.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-service">
                        <div className="icon">
                            <i className="flaticon-video"></i>
                        </div>
                        <div className="content">
                            <h3>Management</h3>
                            <p>We Efficiently allocate resources, adapting to market demands, and ensure customer satisfaction through exceptional products and services.</p>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    </section>
  );
};

export default EasySolutions;