import React from "react";
import ProjectCaseStudyWrapper from "./projCaseStudy";

const AboutUsWrapper = () => {
  return (
    <section class="about-us-wrapper dark section-padding">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-12 pr-xl-5">
                    <div class="section-title mb-30">
                        <p>Why choose us</p>
                        <h1>Innovating Solutions With A Digital Mindset</h1>
                    </div>

                    <p class="pr-md-5">At Abia Tech Hub, we understand that clients have specific needs and expectations when it comes to technology solutions. We aim to exceed those expectations by offering expertise, innovative solutions, quality of service, competitive pricing, and a solid reputation and track record. Our team is dedicated to providing the best possible solutions for your needs, and we're committed to building long-term relationships based on trust and satisfaction. Choose us as your technology partner, and let us help you achieve your goals with confidence.</p>
                    
                    <div class="about-check-list d-flex">
                        <div class="banner bg-cover" style={{backgroundImage: "url('assets/img/about_list.webp')"}}></div>

                        <ul class="checked-list">
                            <li>Expertise</li>
                            <li>Innovative solutions</li>
                            <li>Quality service</li>
                            <li>Reputation and track record</li>
                        </ul>
                    </div>

                </div>

                <div class="col-xl-6 col-md-10 col-lg-6 pl-xl-5 col-12 mt-5 mt-xl-0">
                    <div class="about-thum">
                        <div class="item top-image text-right">
                            <img src="assets/img/about-big-thumb-2.webp" alt="Nigerian tech ecosystem southeast"/>
                        </div>
                        <div class="item buttom-image text-end">
                            <img src="assets/img/about-big-thumb-1.webp" alt="Tech hub Nigeria"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ProjectCaseStudyWrapper />
    </section>
  );
};

export default AboutUsWrapper;