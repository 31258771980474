import React from "react";

const OurServiceItem = (props) => {
  return (
    <div className="col-md-6 col-xl-3 col-12">
        <div className="single-service-item">
            <div className="icon">
                <img src={props.imgUrl} alt="service" />
            </div>
            <h4>{props.caption}</h4>
            <p>{props.description}</p>
            <button>{props.button}</button>
        </div>
    </div>
  );
};

export default OurServiceItem;