import React from "react";

const Topic = (props) => {
  return (
    <div className="row align-items-center">
    <div className="col-12 text-center">
        <div className="section-title">
            <a href="#/" className="theme-btn-sm mb-15" data-aos="fade-up">{props.head}</a>
            <h1 data-aos="fade-up" data-aos-delay="100">{props.info}</h1>
        </div>
    </div>
</div>
  );
};

export default Topic;