import React from "react";

const SubHeroBanner = () => {
  return (
    <div className="col-lg-6 pl-lg-5 mt-5 mt-lg-0 col-12">
        <div className="about-right-img">
            <span className="dot-circle"></span>
            <div className="about-us-img" style={{backgroundImage: "url('assets/img/home1/about-us.webp')"}}>
            </div>
            <span className="triangle-bottom-right"></span>
        </div>
    </div>
  );
}

export default SubHeroBanner;