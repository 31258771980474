import React from "react";
import Topic from "./topic";
import Services from "./services";


const ServiceSectionContainer = () => {
  return (
    <section className="our-service-provide techex-landing-page">
    <div className="container">
        <Topic head={"What We Do"} info={"We Provide Best IT Solutions For Your Business"} />
        <Services />
    </div>
</section>
  );
};
export default ServiceSectionContainer