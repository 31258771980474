import React from "react";
import Topic from "./topic";
import SuccessItem from "./successItem";

const ProjectCaseStudyWrapper = () => {
  return (
    <section className="project-case-study-wrapper techex-landing-page">
        <div className="container">
            <div className="project-case-study-wrapper__circle"></div>
            <Topic head={"Our Completed Projects"} info={"We Have Successfully Completed Many IT Solutions Project"} />
        </div>
        <div className="success-item__wrapper owl-carousel owl-theme">   
        <a href="https://www.myskooltool.com">
          <SuccessItem
            imgUrl="assets/img/techex-landing-page/skooltool.webp"
            head="MySkooltool"
            subHead="School management Software"
          />
        </a>


        <a href="http://academy.abiatechhub.com/">
          <SuccessItem
            imgUrl="assets/img/techex-landing-page/athaca.webp"
            head="ATH Academy"
            subHead="ATH academy site"
          />
        </a>


          <a href="http://workspace.abiatechhub.com/">
        <SuccessItem
          imgUrl="assets/img/techex-landing-page/wspace.webp"
          head="Workspace"
          subHead="Workspace booking app"
        />
        </a>

        <a href="https://pos.onebsgadgets.com/">
        <SuccessItem
          imgUrl="assets/img/techex-landing-page/ath-pos.webp"
          head="Retail POS"
          subHead="Pos system for onebs gadgets"
        />
        </a>

        <a href="http://ntrucks.ng/">
        <SuccessItem
          imgUrl="assets/img/techex-landing-page/ntrucks.webp"
          head="Ntrucks"
          subHead="Ntrucks website"
        />
        </a>

        <a href="http://primeconceptnigeria.com/">
        <SuccessItem
          imgUrl="assets/img/techex-landing-page/primecn.webp"
          head="Prime Concept"
          subHead="Prime concepts website"
        />
        </a>

        <a href="http://nnabuezetiles.com/">
        <SuccessItem
          imgUrl="assets/img/techex-landing-page/nnabueze.webp"
          head="Nnabueze Tiles"
          subHead="Nnabueze Tiles website"
        />
        </a>

        <a href="http://ninaogwo.com/">
        <SuccessItem
          imgUrl="assets/img/techex-landing-page/ninawc.webp"
          head="Nina ogwo"
          subHead="Nina ogwo site"
        />
        </a>

    </div>
    </section>
  );
};

export default ProjectCaseStudyWrapper;