import React from "react";

const BrandImgContainer = (props) => {
  return (
    <div className="single-client">
      <br />
        <img src={props.imgUrl} alt="single client brand-logo"/>
    </div>
  );
};

export default BrandImgContainer;