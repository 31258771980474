import React from "react";
import InfoTips from "./infoTips";
import SubHeroFirst from "./subHeroFirst";

const AboutSubHero = () => {
  return (
    <section className="about-us-wrapper section-padding">
        <div className="container">

            <SubHeroFirst />

            <InfoTips />

        </div>
    </section>
  );
}

export default AboutSubHero;