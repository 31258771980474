import React from "react";
import Topic from "./topic";
import ServiceTips from "./service-tip";

const SubHero = () => {
    return (
        <section className="we-provide-solutions">
            <div className="container">
                <Topic head={"HOW WE CAN HELP YOU"} info={"We Provide Best IT Solutions For Your Business"} />
                <ServiceTips />
            </div>
        </section>
    );
};

export default SubHero;