import React from "react";

const ServiceTipsCard = (props) => {
  return (
    <div className="col-12 col-md-6 col-xl-3" data-aos="fade-up">
        <a href="#/" className="single-provide-solutions" style={{backgroundImage: "url('assets/img/techex-landing-page/we-provide-solutopns-bg.png')"}}>
            <div className="number">
                {props.id}
            </div>
            <div className="content"> 
                <h3>{props.caption}</h3>
                <p>{props.detail}</p>
            </div>
        </a>
    </div>
  );
};

export default ServiceTipsCard;